import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Web3 from "web3";
import MandoXStaking from '../../contracts/MandoXStaking.json';
import lostKids from '../../contracts/lostKids.json';
import borx from '../../images/borx.png';

function Home({wallet, contract, nftContract}) {
  const [stakedCount, setStakedCount] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [reward30, setReward30] = useState(0);
  const [rewardPool, setRewardPool] = useState(0);


  useEffect(() => {
    async function getStatus() {
      const provider = new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/a84a4e44da0346718f6e9f8db7e20c1e');
      const web3 = new Web3(provider);
      let _contract = new web3.eth.Contract(MandoXStaking.abi, MandoXStaking.networks[1].address);
      let _totalStaked = await _contract.methods.totalNFTStaked().call();
      setStakedCount(_totalStaked);
      console.log('_totalStaked', _totalStaked);
      let _reward30 = await _contract.methods.rewardPerKid().call();
      setReward30(_reward30);
      console.log('_reward30', _reward30);
      let _PrerewardPool = await _contract.methods.poolBalance().call() /1000;
      let _rewardPool = _PrerewardPool / 1000000000000000;
      setRewardPool(_rewardPool);
      console.log('_rewardPool', _rewardPool);


      let _nftContract = new web3.eth.Contract(lostKids.abi, lostKids.networks[1].address);
      let _totalSupply = await _nftContract.methods.totalSupply().call();
      setTotalSupply(_totalSupply);
      console.log('_totalSupply', _totalSupply);
    }
    getStatus();

  }, []);

  const startStaking = async() => {

  }
  return (
    <>
      <div className="lgo" style={{ marginTop: 200 }}>
            <img src={borx}/>
      </div>
      <div className="row">
        <div className="offset-md-1 col-md-4 stake-bar">
          <h4>$MANDOX</h4>
          <div className="pl-2 mt-3 pb-3">NFT staking has Ended</div>
          <div className="pl-2 mt-3 pb-3">Gem is Live on MANDOXgem.com</div>
          <div className="pl-2 pt-3 pb-line"></div>

        </div>
        <div className="offset-md-2 col-md-4 stake-bar">
          <h4>NFT Info</h4>
          <div className="pl-2 mt-3 pb-3">Staked NFTs: {stakedCount}</div>
          <div className="pl-2 mt-3 pb-3">Unstaked NFTS: {(totalSupply - stakedCount) >= 0 ? (totalSupply - stakedCount) : 0}</div>
          <div className="pl-2 pt-3 pb-line"></div>
        </div>
      </div>
      <div className="row">
        <div className="mt-5 mx-auto">
          <Link to='/stake' className="btn btn-connect">Go Unstake</Link>
        </div>
      </div>

    </>
  );
}

const mapStateToProps = state => {
  return state;
}

export default connect(mapStateToProps)(Home);
