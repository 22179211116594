import React, {useEffect, useState} from "react";

import largeLogo from '../../images/logo_large.png';
import {ethers} from "ethers";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'font-awesome/css/font-awesome.min.css';
import MandoXStaking from '../../contracts/MandoXStaking.json';
import lostKids from '../../contracts/lostKids.json';
import {Link} from "react-router-dom";
import axios from "axios";
import {submitWallet} from "../../Redux/actions/walletActions";
import {submitContract} from "../../Redux/actions/contractActions";
import {submitNftContract} from "../../Redux/actions/nftContractActions";
import {submitLoading} from "../../Redux/actions/loadingActions";

const nft_contract = "0x267a4af967658d3019c381984f39254dbb2563e7";

function Stake({wallet, contract, nftContract, setIsLoading}) {

  const [balance, setBalance] = useState(0);
  const [stakedCount, setStakedCount] = useState(0);
  const [approvedAll, setApprovedAll] = useState(false);
  // const [loading, setIsLoading] = useState(false);
  const [unstakedNFTs, setUnstakedNFTs] = useState([]);
  const [statusChanged, setStatusChanged] = useState(false);
  const [unstakeAllFlag, setUnstakeAllFlag] = useState(false);
  const [stake30AllFlag, setStake30AllFlag] = useState(false);
  const [tier, setTier] = useState(30);
  const [updatedStakeContract, setUpdatedStakeContract] = useState(false);
  const [stakedNFT, setStakedNFT] = useState([]);

  const getUnStakedNFTs = async () => {
    console.log('getUnStakedNFTs');
    let _tokenIds = await nftContract.tokensOfOwner(wallet);
    let _unstakedNFTs = [];
    console.log('_tokenIds', _tokenIds);
    for (let i = 0; i < _tokenIds.length; i++) {
      let _tokenURI = await nftContract.tokenURI(_tokenIds[i]);
      let _metadata = await axios.get(_tokenURI);
      if (_metadata.data.image) {
        _metadata.data.image = _metadata.data.image.replace('ipfs://', 'https://mandox.mypinata.cloud/ipfs/');
      }
      _metadata.data.token_id = (parseFloat((_tokenIds[i])));
      _metadata.data.selected = false;
      _unstakedNFTs.push(_metadata.data);
    }
    console.log('_unstakedNFTs', _unstakedNFTs);
    setBalance(_unstakedNFTs.length);
    setUnstakedNFTs(_unstakedNFTs);
  }

  const getApproval = async () => {
    let _approvedAll = await nftContract.isApprovedForAll(wallet, MandoXStaking.networks[1].address);
    setApprovedAll(_approvedAll);
  }

  const resetSystem = () => {
    setApprovedAll(false);
    setBalance(0);
    setStakedCount(0);
    setUnstakedNFTs([]);
    setStatusChanged(false);
    setUnstakeAllFlag(false);
    setStakedNFT([]);
  }

  const getStakedNFTs = async () => {
    let _stakedNft = await contract.getStaked(wallet);
    console.log('_stakedNft', _stakedNft);
    setStakedCount(_stakedNft.length);
    let _stakedNFTs = [];
    for (let i = 0; i < _stakedNft.length; i++) {
      let _tokenURI = await nftContract.tokenURI(_stakedNft[i].tokenId);
      console.log('_tokenURI', _tokenURI);
      let _metadata = await axios.get(_tokenURI);
      console.log('_metadata', _metadata);
      if (_metadata.data.image) {
        _metadata.data.image = _metadata.data.image.replace('ipfs://', 'https://mandox.mypinata.cloud/ipfs/');
      }
      _metadata.data.token_id = parseFloat((_stakedNft[i].tokenId));
      _metadata.data.stakeTime = new Date(parseFloat(ethers.utils.formatUnits(_stakedNft[i].stakeTime) * Math.pow(10, 18)) * 1000);
      _metadata.data.stakeTime = _metadata.data.stakeTime.toLocaleDateString();
      _metadata.data.selected = false;
      _stakedNFTs.push(_metadata.data);
    }
    console.log('_stakedNFTs', _stakedNFTs);
    setStakedNFT(_stakedNFTs);
  }

  useEffect(() => {
    async function getStatus() {
      if (!!wallet && !!contract) {
        await getStakedNFTs();
      }
      if (!!wallet && !!nftContract) {
        await getUnStakedNFTs();

        await getApproval();

      } else {
        resetSystem();
      }
    }

    getStatus();
  }, [wallet, contract, nftContract, updatedStakeContract]);

  const approvedNft = async () => {
    if (!wallet || !nftContract) {
      toast.error("CONNECT WALLET FIRST!");
    } else {
      try {
        setIsLoading(true);
        setIsLoading(true);
        const approvalResult = await nftContract.setApprovalForAll(MandoXStaking.networks[1].address, true);
        await approvalResult.wait();
        console.log('approvalResult', approvalResult);
        if (approvalResult?.code === 4001) {
          await toast.success("NFT's linked successfully!");
        } else if (approvalResult?.code) {
          await toast.error("Link failed!");
        } else {
          await toast.success("Link Status updated!");
        }
        setApprovedAll(true);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.log('setApprovalForAll Error', e);
      }
    }
  }

  const toggleSelected = (index) => {
    let _unstakedNFTs = unstakedNFTs;
    _unstakedNFTs[index].selected = !_unstakedNFTs[index].selected;
    console.log('index', index);
    console.log('unstakedNFTs', unstakedNFTs);
    setUnstakedNFTs(_unstakedNFTs);
    setStatusChanged(!statusChanged);
  }

  const selectAllUnstakedNFT = () => {
    let _unstakedNFTs = unstakedNFTs;
    for (let i = 0; i < _unstakedNFTs.length; i++) {
      _unstakedNFTs[i].selected = !unstakeAllFlag;
    }
    setUnstakeAllFlag(!unstakeAllFlag);
    setUnstakedNFTs(_unstakedNFTs);
    setStatusChanged(!statusChanged);
  }


  const stakeNFTs = async () => {
    console.log('unstakedNFTs', unstakedNFTs);
    if (!!wallet && !!contract) {
      let _stakeIDs = [];
      for (let i = 0; i < unstakedNFTs.length; i++) {
        if (unstakedNFTs[i].selected) {
          _stakeIDs.push(parseInt(unstakedNFTs[i].token_id));
        }
      }
      console.log('_stakeIDs', _stakeIDs);
      if (_stakeIDs.length === 0) {
        toast.error("No NFT is selected!");
        return;
      }
      try {
        setIsLoading(true);
        const _transaction = await contract.addManyToRegister(_stakeIDs, {
          from: wallet
        });
        await _transaction.wait();
        toast.success("Staked Successfully!");
        setUpdatedStakeContract(!updatedStakeContract);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log('claim error', err);
        if (err.constructor !== Object) {
          if (String(err).includes('"code":-32000')) {
            toast.error('Error: Not enough Ethereum');
          } else {

            let startingIndex = String(err).indexOf('"message"');
            let endingIndex = String(err).indexOf('"data"');
            let sub1 = String(err).substring(startingIndex, endingIndex);

            let sub2 = sub1.replace('"message":"', '');
            let ret = sub2.replace('",', '');
            toast.error(ret.charAt(0).toUpperCase() + ret.slice(1));
          }
        } else if (err.code === -32000) {
          toast.error('Network busy, please try again in a minute.');
        }
      }
    }
  }

  const selectAllStakedNFT = () => {
    let _stakedNFT = stakedNFT;
    for (let i = 0; i < _stakedNFT.length; i++) {
      if (_stakedNFT[i]) {
        _stakedNFT[i].selected = !_stakedNFT[i].selected;
      }
    }
    setStake30AllFlag(!stake30AllFlag);
    setStakedNFT(_stakedNFT);
    setStatusChanged(!statusChanged);
  }


  const claimRewards = async (unstake = false) => {
    let _claimToken = [];
    for (let i = 0; i < stakedNFT.length; i++) {
      if (stakedNFT[i].selected) {
        _claimToken.push(stakedNFT[i].token_id);
      }
    }
    if (_claimToken.length === 0) {
      toast.error("Select the Staked NFT first!");
      return;
    }
    try {
      setIsLoading(true);
      const res = await contract.claimManyFromRegister(_claimToken, unstake, {from: wallet});
      await res.wait();
      setUpdatedStakeContract(!updatedStakeContract);
      toast.success("Claimed Successfully")
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log('claim error', err);
      if (err.constructor !== Object) {
        if (String(err).includes('"code":-32000')) {
          toast.error('Error: insufficient funds for Gas cost');
        } else {

          let startingIndex = String(err).indexOf('"message"');
          let endingIndex = String(err).indexOf('"data"');
          let sub1 = String(err).substring(startingIndex, endingIndex);

          let sub2 = sub1.replace('"message":"', '');
          let ret = sub2.replace('",', '');
          toast.error(ret.charAt(0).toUpperCase() + ret.slice(1));
        }
      } else if (err.code === -32000) {
        toast.error('Failed, Please try again');
      }
    }

  }



  useEffect(() => {

  }, [statusChanged]);

  return (
    <>
      <div className="home large-container mt-5">
        <div className="text-center">

          
        <div className="YOURSTAKE">
                <h3>
                  Staked NFTs
                </h3>
            </div>
          

          {
            (stakedCount === 0) && (
              <>
                <img className="staked-nft" src={largeLogo}/>
                <h3>You do not have any staked NFTs</h3>
              </>
            )
          }
          {
            (stakedCount !== 0) && (
              <>
                {
                  stakedNFT.filter(each => each).length > 0 && (
                    <div className="row">
                      <div className="col-md-10 text-left">



                        <div className="row">
                          {
                            stakedNFT.map((nft, index) => {
                              if (nft) {
                                return (
                                  <div className="col-md-3 text-left" key={index}>
                                    <img
                                      className={`unstaked-card ${nft.selected ? "selected-card" : "unselected-card"}`}
                                      src={nft.image ? nft.image : largeLogo}
                                      alt={null}
                                      onClick={() => {
                                        nft.selected = !nft.selected;
                                        setStatusChanged(!statusChanged);
                                      }}
                                      style={{width: "100%"}}/>
                                    Token ID: #{nft.token_id}<br/>
                                    Stake Date: {nft.stakeTime}<br/>
                                  </div>
                                )
                              }
                            })
                          }
                        </div>
                      </div>
                      <div className="col-md-2">
                        <a className="btn btn-connect full-width"
                           onClick={() => selectAllStakedNFT()}>{stake30AllFlag ? "Deselect All" : "Select All"}</a>

                        <a className="btn btn-connect full-width mt-3"
                           onClick={() => claimRewards(true)}>Unstake</a>
                      </div>
                    </div>
                  )
                }
                


              </>
            )
          }
        </div>
        


      </div>
    </>
  )
}

const mapStateToProps = state => {
  return state;
}
export default connect(mapStateToProps)(Stake);

